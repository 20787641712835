import { useState } from 'react'

import {
  AtSymbolIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline'
import {
  ChevronDoubleDownIcon,
  PhoneArrowDownLeftIcon,
} from '@heroicons/react/24/solid'
import Stack from '@mui/material/Stack'

import { Metadata } from '@redwoodjs/web'

import IconButton from 'src/components/IconButton/IconButton'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Authors from 'src/components/Library/Authors/Authors'
import Breadcrumbs from 'src/components/Library/Breadcrumbs/Breadcrumbs'
import Button from 'src/components/Library/Button/Button'
import CopyToClipboardButton from 'src/components/Library/CopyToClipboardButton/CopyToClipboardButton'
import CustomDropdown from 'src/components/Library/CustomDropdown/CustomDropdown'
import Divider from 'src/components/Library/Divider/Divider'
import DocumentEditor from 'src/components/Library/DocumentEditor/DocumentEditor'
import EditCard from 'src/components/Library/EditCard/EditCard'
import Empty from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import Switch from 'src/components/Library/Switch/Switch'
import TemplateCard from 'src/components/Library/TemplateCard/TemplateCard'
import TrackItemCard from 'src/components/Library/TrackItemCard/TrackItemCard'
import UnderlinedValue from 'src/components/Library/UnderlinedValue/UnderlinedValue'
import UserHoverCard from 'src/components/Library/UserHoverCard/UserHoverCard'

const DevToolsPage = () => {
  const [Data, setData] = useState({})
  const [reader, setReader] = useState(false)
  const TESTJSON = {
    time: 1675838757224,
    blocks: [
      {
        id: 'Bk-DqRItLI',
        type: 'paragraph',
        data: {
          text: 'The example of text that was written in <b>one of popular</b> text editors.',
        },
      },
      {
        id: '0SwOti75zI',
        type: 'header',
        data: { text: 'With the header of&nbsp;course', level: 2 },
      },
      {
        id: 'EbOskZXD5s',
        type: 'paragraph',
        data: { text: 'So what do we have?' },
      },
      {
        id: 'Q5gNWdg6eD',
        type: 'paragraph',
        data: { text: 'wow this is a cool example' },
      },
      {
        id: 'qITF_jOBRZ',
        type: 'warning',
        data: { title: 'warning', message: 'oh noooo' },
      },
      {
        id: 'DBCMDO4Sif',
        type: 'quote',
        data: { text: 'test', caption: 'me william<br>', alignment: 'left' },
      },
      {
        id: '8ZeMV_UUni',
        type: 'checklist',
        data: {
          items: [
            { text: '1', checked: false },
            { text: '2', checked: false },
            { text: '3', checked: false },
            { text: '4', checked: false },
            { text: '5', checked: false },
            { text: '6', checked: false },
            { text: '7', checked: false },
            { text: '8', checked: false },
            { text: '9', checked: false },
            { text: '10', checked: false },
          ],
        },
      },
      {
        id: 'v9ybcI4o_y',
        type: 'table',
        data: {
          withHeadings: false,
          content: [
            ['1', 'a'],
            ['2', 'b'],
          ],
        },
      },
      {
        id: '150M1JU4i6',
        type: 'code',
        data: {
          code: '// Hello World\nconst handleSave = React.useCallback(async () => {\n  const savedData = await editorCore.current.dangerouslyLowLevelInstance?.save();\n}, [])\n\n\n\n\n\n',
          language: 'plain',
          showlinenumbers: true,
        },
      },
    ],
    version: '2.26.5',
  }
  const [name, setName] = useState('')
  const testLinks = [
    { name: 'Home', href: '/' },
    {
      name: 'DevTools',
      href: '/devtools',
      icon: <ChevronDoubleDownIcon className={'w-3 h-3'} />,
    },
  ]
  const testUser = {
    id: 499,
    avatarUrl:
      'https://hubs-dev-storage.s3.ap-southeast-2.amazonaws.com/1/user/profile/avatar-179.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA4DAV7S27DVOQTOXZ%2F20230626%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20230626T004605Z&X-Amz-Expires=1800&X-Amz-Signature=ec4b5f7413213728d38a1192a2fcdb00c6e5ed5216600f174fb14c99afa59f0e&X-Amz-SignedHeaders=host&x-id=GetObject',
    email: 'berenice_langosh_yj3y32@stafflink.com.au',
    name: 'Kent Zulauf',
    position: 'International Functionality Consultant',
    __typename: 'User',
  }

  const authors1 = {
    createdByUser: { id: 1, name: 'AA', avatarUrl: '' },
    updatedByUser: { id: 2, name: 'BB', avatarUrl: '' },
  }

  const authors2 = {
    createdByUser: { id: 1, name: 'A. Aardvark', avatarUrl: '/' },
    updatedByUser: { id: 1, name: 'A. Aardvark', avatarUrl: '/' },
  }

  const text = 'Lorem ipsum'
  const label = 'Copy to Clipboard'

  const buttonTitle = 'Open Menu'
  const buttonProps = {}

  return (
    <>
      <Metadata title="DevTools" description="DevTools" />
      {process.env.ENVIRONMENT === 'local' ? (
        <>
          <div className="h-[50vh]">
            <LoadingSpinner />
          </div>
          <IconButton>
            <MagnifyingGlassCircleIcon className="w-6 h-6" />
          </IconButton>
          <CustomDropdown buttonTitle={buttonTitle} buttonProps={buttonProps}>
            <p>Aaa</p>
          </CustomDropdown>
          <CopyToClipboardButton text={text} label={label} />
          <Authors authors={authors1} createdAt={'aa'} updatedAt={'bb'} />
          <Authors authors={authors2} createdAt={'aa'} updatedAt={'bb'} />
          <Accordion title={'Accordion'} expanded={true}>
            <p>Accordion Content</p>
          </Accordion>
          <div className={'m-6'}>
            <EditCard>
              <UserHoverCard user={testUser} membershipId={5} />
              <UserHoverCard user={testUser} membershipId={5} />
              <UserHoverCard user={testUser} membershipId={5} />
              <UserHoverCard user={testUser} membershipId={5} />
            </EditCard>
            <div>
              Searching for: {name}
              <br />
              <SearchField placeholder="Search Templates" onChange={setName} />
            </div>
            <br />
            <Stack spacing={1} direction={'row'}>
              <UnderlinedValue value={10} color={'red'} />
              <UnderlinedValue value={10} color={'yellow'} />
              <UnderlinedValue value={10} color={'green'} />
            </Stack>
            <br />
            <Empty textPosition="top" />
            <br />
            <Breadcrumbs links={testLinks} />
            <div className="w-40">
              <Button>Test</Button>
              <Button variant="outlined">Test</Button>
              <Button variant="text">Test</Button>
              <Button variant="contained">Test</Button>
              <Button variant="outlined" onClick={() => setReader(!reader)}>
                flip
              </Button>
              <div className="m-2">
                {reader ? 'true' : 'false'}
                <Button
                  variant="outlined"
                  startIcon={<PhoneArrowDownLeftIcon className="w-4 h-4" />}
                  href={'https://whothefuckismydndcharacter.com/'}
                >
                  Test
                </Button>
              </div>
            </div>
          </div>
          <br />
          <Divider chip label={'Start'} />
          <br />
          <div className={'m-4'}>
            <Accordion title="Templates">
              <div className="w-1/2 m-4">
                <TemplateCard author={'Stafflink'} createdTime={'2023-02-15'}>
                  {'today'}
                </TemplateCard>
                <TemplateCard author={'Stafflink'} createdTime={'2023-02-14'}>
                  {'day'}
                  <Switch />
                </TemplateCard>
                <TemplateCard author={'Stafflink'} createdTime={'2023-01-14'}>
                  {'month'}
                </TemplateCard>
                <TemplateCard author={'Stafflink'} createdTime={'2022-02-14'}>
                  {'year'}
                </TemplateCard>
              </div>
            </Accordion>
          </div>
          <div className="w-1/2 m-4">
            <TrackItemCard
              headerIcon={<AtSymbolIcon className="w-4 h-4 text-indigo-600" />}
              title={'objective'}
              subheader={'Offshore staff onboarding'}
            />
          </div>
          <div className="w-1/2 justify-center">
            <DocumentEditor
              defaultValue={TESTJSON}
              setData={setData}
              readerMode={reader}
            />
          </div>
        </>
      ) : (
        <div className="bg-repeat w-full h-screen bg-[url('https://gifdb.com/images/file/mystery-dancing-duck-head-bopping-znksg56rnak27usz.gif')]"></div>
      )}
    </>
  )
}

export default DevToolsPage
